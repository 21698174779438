import { template as template_0795b22c344b4a30a0ff6ac924532999 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0795b22c344b4a30a0ff6ac924532999(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
